import { UserService } from './../share/services/user.service';
import { User } from 'src/app/share/models/user.model';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  public currentUser: Observable<User>;

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this.currentUser = this._userService.getCurrentUser();
  }
}
