import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './../../share/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/share/models/user.model';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private _formBuilderService: FormBuilder,
    private _authService: AuthService,
    private _routerService: Router,
    private _snackBarService: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilderService.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  public submit(): void {
    if (this.form.invalid) return;
    this._authService.signIn(this.form.value).subscribe((token: string) => {
      this._snackBarService.open(`Bienvenue ${ this.form.get('email').value }`, 'Ok');
      this._routerService.navigate(['/']);
    }, err => {
      this._snackBarService.open('Impossible de se connecter', 'Ok');
    });
  }

}
