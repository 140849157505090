<input
  #checkbox
  class="menu-icon"
  type="checkbox"
  id="menu-icon"
  name="menu-icon"
/>
<label for="menu-icon"></label>

<nav class="nav">
  <ul class="pt-5">
    <li>
      <a routerLink="/home"
         routerLinkActive="active"
         (click)="checkbox.checked = false">
        {{ "global.home" | translate }}
      </a>
    </li>
    <li>
      <a routerLink="/about-me"
         routerLinkActive="active"
         (click)="checkbox.checked = false">
        {{ "global.about-me" | translate }}
      </a>
    </li>
    <li>
      <a routerLink="/skills"
         routerLinkActive="active"
         (click)="checkbox.checked = false">
        {{ "global.skills" | translate }}
      </a>
    </li>
    <li>
      <a routerLink="/projects"
         routerLinkActive="active"
         (click)="checkbox.checked = false">
        {{ "global.projects" | translate }}
      </a>
    </li>
    <div class="buttons">
      <!-- LOG IN BUTTON -->
      <app-wave-button
        [name]="'global.login'"
        [link]="'/login'"
        (clickEmitter)="checkbox.checked = false">
      </app-wave-button>
      <!-- <app-wave-button
        [name]="'global.register'"
        [link]="'/register'"
        (clickEmitter)="checkbox.checked = false"
      >
      </app-wave-button> -->
    </div>
    <li>
      <label>
        <select #langSelect (change)="languageChanged(langSelect.value)">
          <option
            *ngFor="let language of getLanguages()"
            [value]="language"
            [selected]="isCurrentLanguage(language)">
            {{ language }}
          </option>
        </select>
      </label>
    </li>
  </ul>
</nav>
