import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('jwt');
        if (token) {
            const authRequest = req.clone({
                headers: req.headers.set('Authorization', token)
            });
            return next.handle(authRequest);
        } else {
            return next.handle(req);
        }
    }
}
