<div class="loader">
	<div class="loader-inner">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="100px">
            <h1>4</h1>
		<div class="loader-line-wrap">
			<div class="loader-line"></div>
		</div>
		<div class="loader-line-wrap">
			<div class="loader-line"></div>
		</div>
		<div class="loader-line-wrap">
			<div class="loader-line"></div>
		</div>
		<div class="loader-line-wrap">
			<div class="loader-line"></div>
		</div>
		<div class="loader-line-wrap">
			<div class="loader-line"></div>
        </div>
        <h1>4</h1>
    </div>
    </div>
</div>