import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(
    private _translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
  }

  public getLanguages(): string[] {
    return this._translateService.getLangs();
  }

  public isCurrentLanguage(language: string): boolean {
    return this._translateService.currentLang === language;
  }

  public languageChanged(language: string): void {
    this._translateService.use(language);
  }
}
