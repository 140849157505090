<div class="my-5 py-5" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title>Mon compte</mat-card-title>
    <mat-card-content *ngIf="currentUser | async; let user"
                      fxLayout="column"
                      fxLayoutGap="15px">
      <div>email: {{ user.email }}</div>
      <div>nom: {{ user.name }}</div>
    </mat-card-content>
  </mat-card>
</div>
