import { ProfileComponent } from './profile/profile.component';
import { SigninComponent } from './auth/signin/signin.component';
import { HomepageComponent } from './homepage/homepage.component';
import { Route } from '@angular/router';
import { AuthGuard } from './share/guards/auth.guard';
import { AboutComponent } from './about/about.component';
import { SkillsComponent } from './skills/skills.component';
import { ProjectsComponent } from './projects/projects.component';
import { NotFoundComponent } from './share/components/not-found/not-found.component';

export const APP_ROUTING: Route[] = [
    { path : '', redirectTo: 'home', pathMatch: 'full'},
    { path: 'home', component: HomepageComponent },
    { path: 'about-me', component: AboutComponent },
    { path: 'skills', component: SkillsComponent },
    { path: 'projects', component: ProjectsComponent },
    /* { path: 'register', component: SignupComponent }, */
    { path: 'login', component: SigninComponent },
    { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];
