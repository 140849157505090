<div class="skills-container" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="80px">
    <div
      class="skill-item clickable"
      fxFlex="auto"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://angular.io/')"
    >
      <img src="assets/images/angular.png" alt="Angular" />
      <h3 class="skill-title">Angular v7 → v9</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://material.angular.io/')"
    >
      <img src="assets/images/angular-material.svg" alt="Angular material" />
      <h3 class="skill-title">Angular Material v7 → v9</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://aws.amazon.com/fr/?nc2=h_lg')"
    >
      <img src="assets/images/aws.png" alt="Aws" />
      <h3 class="skill-title">Amazon Web Services</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://github.com/')"
    >
      <img src="assets/images/github.png" alt="Github" />
      <h3 class="skill-title">GitHub</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://ionicframework.com/')"
    >
      <img src="assets/images/ionic.png" alt="Ionic" />
      <h3 class="skill-title">Ionic</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('http://www.javaserverfaces.org/')"
    >
      <img src="assets/images/jsf.png" alt="Java Server Faces" />
      <h3 class="skill-title">Java Server Faces</h3>
    </div>
    <div
      class="skill-item clickable"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="12px"
      (click)="navigateTo('https://spring.io/')"
    >
      <img src="assets/images/spring.png" alt="Spring" />
      <h3 class="skill-title">Spring</h3>
    </div>
  </div>
</div>
