import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from './../../share/models/user.model';
import { AuthService } from './../../share/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private _formBuilderService: FormBuilder,
    private _authService: AuthService,
    private _routerService: Router,
    private _snackBarService: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilderService.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required]]
    }, {
      validators: this._passwordMatch
    });
  }

  public submit(): void {
    if (this.form.invalid) return;
    this._authService.signUp(this.form.value).subscribe((user: User) => {
      this._routerService.navigate(['/signin']);
      this._snackBarService.open('Inscription effectuée', 'Ok');
    }, err => {
      this._snackBarService.open('Erreur lors de l\'inscription', 'Ok');
    });
  }

  private _passwordMatch(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirm').value;
    return password === confirmPassword ? null : group.get('confirm').setErrors({notMatching: true});
  }
}
