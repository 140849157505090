<div fxLayout="row" fxLayoutAlign="center center" id="sign-in-container">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card fxFlex="300px">
      <mat-card-title class="text-center">{{
        "global.login" | translate
      }}</mat-card-title>
      <mat-card-content
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="15px"
      >
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.email" | translate }}</mat-label>
          <input matInput type="text" [formControlName]="'email'" />
          <mat-error>{{ "global.email-error" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.password" | translate }}</mat-label>
          <input matInput type="password" [formControlName]="'password'" />
        </mat-form-field>
      </mat-card-content>
      
      <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
        <app-wave-button
          [name]="'global.login'"
          (clickEmitter)="submit()"
        ></app-wave-button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
