import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, AfterViewInit {

  @ViewChild('cursor') public cursor: ElementRef;
  public onMobile: boolean = false;

  constructor(
    private _platformService: Platform
  ) {
    if (this._platformService.ANDROID || this._platformService.IOS) this.onMobile = true;
    else this.onMobile = false;
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // trigger event of mouse
    if (!this.onMobile) {
      this._mouseMoveEvent();
    }
  }

  private _mouseMoveEvent(): void {
    fromEvent(document, 'mousemove').subscribe((event: any) => {
      this.cursor.nativeElement.style.cssText = "left: " + event.clientX + "px; top: " + event.clientY + "px;";
    });
  }
}
