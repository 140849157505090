<div fxLayout="row" fxLayoutAlign="center center" id="sign-up-container">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card fxFlex="300px">
      <mat-card-title class="text-center">
        {{ "global.register" | translate }}
      </mat-card-title>

      <mat-card-content fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.email" | translate }}</mat-label>
          <input matInput type="text" [formControlName]="'email'" />
          <mat-error>{{ "global.email-error" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.name" | translate }}</mat-label>
          <input matInput type="text" [formControlName]="'name'" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.password" | translate }}</mat-label>
          <input matInput type="password" [formControlName]="'password'" />
          <mat-error>{{ "global.password-error" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.confirm" | translate }}</mat-label>
          <input matInput type="password" [formControlName]="'confirm'" />
          <mat-error *ngIf="form.get('confirm').hasError('notMatching')">{{
            "global.no-match" | translate
          }}</mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
        <app-wave-button
          [name]="'global.register'"
          (clickEmitter)="submit()"
        ></app-wave-button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
