import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-wave-button',
  templateUrl: './wave-button.component.html',
  styleUrls: ['./wave-button.component.css']
})
export class WaveButtonComponent implements OnInit {

  @Input() link: string;
  @Input() name: string;

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public clickTrigger(): void {
    this.clickEmitter.emit();
  }

}
