<div class="about-container" fxLayout="column" fxLayoutAlign="center center">
  <mat-card fxFlex="300px">
    <mat-card-content fxLayout="column"
                      fxLayoutAlign="center center"
                      fxLayoutGap="12px">
      <!-- AVATAR -->
      <div class="avatar">
        <img src="assets/amaury.png" alt="Amaury Lapaque"/>
      </div>
      <!-- TITLE -->
      <div class="title">
        <h1>Amaury Lapaque</h1>
      </div>
      <!-- LOCATION -->
      <div class="location"
           fxLayout="row"
           fxLayoutAlign="center center"
           fxLayoutGap="8px">
        <mat-icon svgIcon="localisation"></mat-icon>
        <span>{{ 'global.localisation' | translate }}</span>
      </div>

      <!-- CARD INFO -->
      <div class="card-inf"
           fxLayout="row"
           fxlayoutAlign="center center"
           fxLayoutGap="25px">
        <div class="card-inf__item text-center">
          <div class="card-inf__title">26</div>
          <div class="card-inf__txt">{{ 'global.age' | translate }}</div>
        </div>
        <div class="card-inf__item text-center">
          <div class="card-inf__title">1.5y</div>
          <div class="card-inf__txt">{{ 'global.experience' | translate }}</div>
        </div>
        <div class="card-inf__item text-center">
          <div class="card-inf__title">6</div>
          <div class="card-inf__txt">{{ 'global.projects' | translate | lowercase }}</div>
        </div>
      </div>

      <!-- SOCIALS -->
      <div class="card-socials"
           fxLayout="row"
           fxlayoutAlign="center center"
           fxLayoutGap="60px">
        <div class="card-socials__item">
          <button class="custom-btn"
                  mat-icon-button
                  aria-label="Linkedin"
                  [matTooltip]="'Linkedin'"
                  (click)="navigateTo('https://www.linkedin.com/in/amaury-lapaque/')">
            <mat-icon svgIcon="linkedin"></mat-icon>
          </button>
        </div>
        <div class="card-socials__item">
          <button class="custom-btn"
                  mat-icon-button
                  aria-label="Github"
                  [matTooltip]="'Github'"
                  (click)="navigateTo('https://github.com/ALapaque')">
            <mat-icon svgIcon="github"></mat-icon>
          </button>
        </div>
        <div class="card-socials__item">
          <button class="custom-btn"
                  mat-icon-button
                  aria-label="Dyma"
                  [matTooltip]="'Dyma'"
                  (click)="navigateTo('https://dyma.fr/developer/5e983d559981bc1d2f6be8bc')">
            <mat-icon svgIcon="dyma"></mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
