<div *ngIf="link; then linkBlock; else noLink"></div>

<ng-template #linkBlock>
<button type="submit" routerLink="{{ link }}" (click)="clickTrigger()">
  <span>{{ name | translate }}</span>
  <div class="wave"></div>
</button>
</ng-template>


<ng-template #noLink>
  <button type="submit" (click)="clickTrigger()">
    <span>{{ name | translate }}</span>
    <div class="wave"></div>
  </button>
</ng-template>


