import { JsonToken } from './../models/json-web-token.model';
import { User } from './../models/user.model';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public jwtToken: BehaviorSubject<JsonToken> = new BehaviorSubject({
    isAuthenticated: null,
    token: null,
  });

  constructor(private _httpClient: HttpClient) {
    this._initToken();
  }

  public signUp(user: {
    email: string;
    name: string;
    password: string;
  }): Observable<User> {
    return this._httpClient.post<User>(`/api/auth/register`, user);
  }

  public signIn(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return of(
      this.jwtToken.next({
        isAuthenticated: true,
        token: null,
      })
    );
    // return this._httpClient.post<string>(`/api/auth/login`, credentials).pipe(
    //   tap((token: string) => {
    //     this.jwtToken.next({
    //       isAuthenticated: true,
    //       token: token,
    //     });
    //     localStorage.setItem('jwt', token);
    //   })
    // );
  }

  public logout(): void {
    this.jwtToken.next({
      isAuthenticated: false,
      token: null,
    });
    localStorage.removeItem('jwt');
  }

  private _initToken(): void {
    const token: string = localStorage.getItem('jwt');
    if (token) {
      this.jwtToken.next({
        isAuthenticated: true,
        token: token,
      });
    } else {
      this.jwtToken.next({
        isAuthenticated: false,
        token: null,
      });
    }
  }
}
