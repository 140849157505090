import { Component } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private  _showLoading: boolean = true;
  public firstTimeLoading: boolean = true;

  constructor(
    private _router: Router,
    private _swUpdate: SwUpdate,
    private _translateService: TranslateService,
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._registerLanguages();
    this._registerIcons();

    this._router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });

    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe((event) => {
        if (confirm("Mise à jour disponnible, souhaitez-vous l'appliquer ?")) {
          window.location.reload();
        }
      });
    }
  }

  get showLoading(): boolean {
    return this._showLoading;
  }

  set showLoading(status: boolean) {
    this._showLoading = status;
  }

  // show and hides the loading spinner during RouterEvent changes
  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoading = true;
    }
    if (event instanceof NavigationEnd) {
      if ((event.url.includes('home') && this.firstTimeLoading) || this.firstTimeLoading) {
        setTimeout(() => {
          this.firstTimeLoading = false;
          this.showLoading = false;
        }, 1500);
      } else {
        this.showLoading = false;
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showLoading = false;
    }
    if (event instanceof NavigationError) {
      this.showLoading = false;
    }
  }

  private _registerLanguages(): void {
    const browserLang = this._translateService.getBrowserLang();

    this._translateService.addLangs(['en-US', 'fr-FR']);
    this._translateService.setDefaultLang('en-US');

    this._translateService.use(
      browserLang.match(/fr|fr-FR/) ? 'fr-FR' : 'en-US'
    );
  }

  private _registerIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'linkedin',
      this._sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/social/linkedin.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'github',
      this._sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/social/github.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'dyma',
      this._sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/social/layer.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'localisation',
      this._sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/social/location.svg'
      )
    );
  }
}
