<div class="homepage-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="avatar">
    <img
      src="assets/amaury.png"
      alt="Amaury Lapaque"
    />
  </div>

  <h1 class="mb-4">Amaury Lapaque</h1>
  <p class="p-3 text-center">{{ "home.sub-title" | translate }}</p>
</div>

<!-- cursor div for the visual animation -->

<div #cursor class="cursor" *ngIf="!onMobile">
  <div class="inner-cursor"></div>
</div>
