<div class="projects-container">
  <div class="accordion-container">
    <mat-accordion multi>
      <!-- JK STUDIO PANEL -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "project.jk.title" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ "project.jk.description" | translate }}</p>
        <u>{{ "project.techno" | translate }}</u>
        <div class="mt-3"
             fxLayout="row"
             fxLayoutAlign="space-around center">
          <img src="assets/images/php.png" alt="php"/>
          <img src="assets/images/wp.png"
               alt="wordpress"/>
          <img src="assets/images/themeforest.png" alt="themeforest"/>
        </div>
        <mat-action-row fxLayout="row" fxLayoutAlign="space-between center">
          <b><i>{{ "global.DONE" | translate | uppercase }}</i></b>
          <button mat-icon-button
                  aria-label="music player"
                  (click)="navigateTo('https://jkstudio.fr/')">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- DESIGN TEMPLATE 1 -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "project.template-1.title" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ "project.template-1.description" | translate }}</p>
        <u>{{ "project.techno" | translate }}</u>
        <div class="mt-3"
             fxLayout="row"
             fxLayoutAlign="space-around center">
          <img src="assets/images/html.png" alt="html"/>
          <img src="assets/images/css.png"
               alt="css"/>
          <img src="assets/images/js.png" alt="js"/>
        </div>
        <mat-action-row fxLayout="row" fxLayoutAlign="space-between center">
          <b><i>{{ "global.WIP" | translate | uppercase }}</i></b>
          <button mat-icon-button
                  aria-label="music player"
                  (click)="navigateTo('https://alapaque.github.io/projects/spa_scrolling/')">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- AWS PANEL -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "project.aws.title" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ "project.aws.description" | translate }}</p>
        <u>{{ "project.techno" | translate }}</u>
        <div class="mt-3" fxLayout="row" fxLayoutAlign="space-around center">
          <img src="assets/images/angular.png" alt="angular"/>
          <img src="assets/images/angular-material.svg" alt="angular-material"/>
          <img src="assets/images/aws.png" alt="aws"/>
          <img src="assets/images/ionic.png" alt="ionic"/>
        </div>
        <mat-action-row fxLayout="row" fxLayoutAlign="space-between center">
          <b><i>{{ "global.WIP" | translate | uppercase }}</i></b>
          <button mat-icon-button
                  aria-label="aws"
                  (click)="navigateTo('https://aws.must.be')">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- NEOMORPHIC PANEL -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "project.player.title" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ "project.player.description" | translate }}</p>
        <u>{{ "project.techno" | translate }}</u>
        <div class="mt-3" fxLayout="row" fxLayoutAlign="space-around center">
          <img src="assets/images/angular.png" alt="angular"/>
          <img src="assets/images/angular-material.svg"
               alt="angular-material"/>
          <img src="assets/images/ionic.png" alt="ionic"/>
        </div>
        <mat-action-row fxLayout="row" fxLayoutAlign="space-between center">
          <b><i>{{ "global.WIP" | translate | uppercase }}</i></b>
          <button mat-icon-button
                  aria-label="music player"
                  (click)="navigateTo('https://player.must.be')">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
